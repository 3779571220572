function toggleNavbar() {
  const navbarmb = document.getElementById("navbar-hamburger");
  const navbarIdle = document.getElementById("navbarIdle");

  if (navbarmb.style.display === "none") {
    document
      .getElementById("navbar-wrapper")
      .classList.add("animate__animated", "animate__fadeInRight");
    navbarIdle.style.display = "none";
    navbarmb.style.display = "inline";
  } else if (navbarmb.style.display !== "none") {
    console.log("here");
    navbarmb.style.display = "none";
    navbarIdle.style.display = "flex";
  }
}

function toggleScroll() {
  const body = document.body;

  if ((body.syle.overflowY = "hidden")) {
    body.syle.overflowY = "scroll";
  } else {
    body.syle.overflowY = "hidden";
  }
}

function navbarScroll() {
  const posy = window.pageYOffset | document.body.scrollTop;
  let nav;

  if (window.innerWidth < 1900) {
    nav = document.getElementById("navbarIdle");
  } else {
    nav = document.getElementById("nav-d");
  }
  if (posy !== 0) {
    nav.style.background = "white";
    nav.style.borderBottom = "5px solid rgba(255, 255, 255, 0.2)";
  } else {
    nav.style.background = "none";
    nav.style.borderBottom = "none";
  }
}

var prevScrollpos = window.pageYOffset;
window.onscroll = function () {
  var currentScrollPos = window.pageYOffset;
  const posy = window.pageYOffset | document.body.scrollTop;
  let nav;

  if (window.innerWidth < 1900) {
    nav = document.getElementById("navbarIdle");
  } else {
    nav = document.getElementById("nav-d");
  }
  if (posy == 0) {
    nav.style.background = "none";
  } else {
    nav.style.background = "white";
  }
  if (prevScrollpos > currentScrollPos) {
    nav.style.top = "0";
  } else {
    nav.style.top = "-200px";
  }
  prevScrollpos = currentScrollPos;
};
