function sendData() {
  fetch("https://api.apispreadsheets.com/data/17566/", {
    method: "POST",
    body: JSON.stringify({
      data: {
        Nom: document.getElementsByTagName("input")[0].value,
        Email: document.getElementsByTagName("input")[1].value,
        Objet: document.getElementsByTagName("input")[2].value,
        Téléphone: document.getElementsByTagName("input")[3].value,
        Message: document.getElementsByTagName("textarea")[0].value,
      },
    }),
  }).then((res) => {
    if (res.status === 201) {
      // SUCCESS
    } else {
      // ERROR
    }
  });
}
