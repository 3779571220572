const trad = {
  fr: [
    "À propos de nous ",
    "Nos services",
    "Nos produits phare",
    "Nos coordonnées",
    "Contactez-nous",
    "À propos de nous ",
    "Nos services",
    "Nos produits phare",
    "Nos coordonnées",
    "Contactez-nous",
    "<h3>Chez HYS</h3><h1><b>Répondre à <br>VOS BESOINS</b> est <br>NOTRE <b>MISSION</b></h1>",
    "Contactez-nous",
    "À propos de nous",
    "HYS a été créée en 2018 par Jian Huang avec une envie de fournir un service de qualité et personnalisé dans la vente des produits de la mer. Afin de garantir une qualité constante, nous contrôlons la fraîcheur de nos produits à chaque étape, de l’achat jusqu’à la livraison et ce, par nos soins ou par nos prestataires de transport. Attentif aux besoins de nos clients, nous savons apporter la meilleure réponse possible rendant ainsi l’expérience client optimale et nos relations commerciales pérennes. Notre situation géographique privilégiée nous permet de nous approvisionner sur une grande diversité de produits tels les poissons fumés, les poissons surgelés et des produits transformés sur notre zone d’activité, offrant ainsi des opportunités uniques à chaque client. Nous sommes, par ailleurs, sensibles et conscients du développement rapide du monde et proposons donc un service d’exportation en partenariat avec différentes entreprises et plus particulièrement en Chine.",
    "Achat",
    "Nos achats sont fait de manière directe permettant ainsi un tarif compétitif sur une large sélection de produits provenant de la pêche du Boulonnais mais également de diverses provenances via nos réseaux logistiques (huîtres de Normandie, King crabes de Norvège, Langoustes, œufs de saumon, produits élaborés…).",
    "Conditionnement",
    "Disposant d’un numéro d’agrément sanitaire, nous avons la possibilité de réemballer nos produits nous permettant de vérifier leur fraîcheur mais également de garantir une redistribution des produits afin de répondre à chaque demande.",
    "Livraison",
    "Nous proposons un service de livraison assuré par notre équipe et par nos prestataires de livraison, répondant ainsi à vos besoins quelque soit votre situation géographique.",
    "Nos produits phare",
    "Voir tous nos produits",
    "Nos Coordonnées",
    "<h3>Adresse:</h3><p>18 rue d'Alsace,<br>62200 Boulogne-sur-mer</p><h3>Télephone:</h3><p>+33 (0) 9 80 44 94 89</p><h3>Email:</h3><p>sashys2018@gmail.com</p>",
    "Contactez-nous",
    "Nom",
    "Email",
    "Objet",
    "Téléphone",
    "Message",
    "Envoyer",
    "HYS propose un service garantissant une qualité constante, à un prix compétitif et ce, en restant à votre écoute.",
    "18 rue d'Alsace,<br>62200 Boulogne-sur-mer<br>+33 (0) 9 80 44 94 89<br>sashys2018@gmail.com",
    "Copyright© 2021.All right Reserved",
    "Privacy Policy",
  ],
  ch: [
    "关于 HYS 的公司",
    "服务提供",
    "旗舰产品",
    "联系方式",
    "联系我们",
    "关于 HYS 的公司",
    "服务",
    "旗舰产品",
    "联络方式",
    "联系我们",
    "<h1 style='font-size: 48px;'>满足您的需求是我们的使命 !</h1>",
    "联系我们",
    "关于我们公司",
    "公司旨在给客户提供最佳的海鲜产品以及个性化的服务。我们确保从原材料采购到产品交付，每一个环节都由欧洲最专业的运输团队负责，以保证产品质量的新鲜度和稳定性。客户的需求就是我们前进的动力，我们善于并非常乐意倾听客户的心声，以确保给客户带来最佳的服务体验，从而使得我们的合作关系持久稳定！公司的得天独厚的地理位置为我们提供了极其丰富的多元化产品，例如各类熏鱼产品，冷冻包装鱼类以及各种包装类海鲜产品。为不同需求的客户提供最多最佳的选择。在世界经济一体化的大背景下，我们顺应潮流与多家不同大型国际公司合作为多个国家提供出口服务，尤其是针对中国市场。",
    "采购",
    "公司大部分的产品都是从源头直接采购并通过我们自己的物流系统或法国最专业的冷链物流团 队直接配送到客户手中，从而确保了我们的产品的市场竞争力。<br>（诺曼底牡蛎，挪威帝王蟹，玫瑰龙虾，三文鱼以及各种包装规格海鲜等等）。",
    "公司资质",
    "公司拥有法国政府认证的食品卫生许可证，以便于我们在第一时间对不同的产品按需再包装来确保产品的新鲜度，和满足客户的不同需求。",
    "物流服务",
    "优越的地理位置以及公司一流的物流团队能够确保将最新鲜的产品在第一时间运送到世界各地的客户手中。",
    "旗舰产品",
    "产品目录",
    "联系方式",
    "<h3>公司地址:</h3><p>18 rue d'Alsace,<br>62200 Boulogne-sur-mer</p><h3>电话:</h3><p>+33 (0) 9 80 44 94 89</p><h3>邮箱地址:</h3><p>sashys2018@gmail.com</p>",
    "联系我们",
    "姓名",
    "邮箱",
    "主题",
    "电话",
    "请在此书写.....",
    "发送",
    "为了保证产品质量以及为您提供最优惠的价格，我们非常乐意倾听您的需求",
    "18 rue d'Alsace,<br>62200 Boulogne-sur-mer<br>+33 (0) 9 80 44 94 89<br>sashys2018@gmail.com",
    "Copyright© 2021.All right Reserved",
    "Privacy Policy",
  ],
  en: [
    "About us",
    "Our services",
    "Our main products",
    "Contact info",
    "Get in touch",
    "About us",
    "Our services",
    "Our main products",
    "Contact info",
    "Get in touch",
    "<h1><b>Answering<br>YOUR NEEDS</b> is <br>OUR <b>MISSION</b></h1>",
    "Get in touch",
    "About us",
    "Hys was established in 2018 by Jian Huang with a desire to provide quality and personalized services in the sale of seafood. In order to guarantee constant quality, we control the freshness  of our products at every stage, from purchase to delivery, by us or our provider's transport service. We take special attention to the needs of all our customers, we do all we can to provide the best answer. All this to give to our customers the optimal customer experience, and it keeps our  sales relationships sustainable. Our privileged geographical location allows us to supply us with a wide variety of products, such as smoked fish, frozen fish and products processed in our area. This offers us unique opportunities to each client. Moreover, we are very sensitive and aware  of the rapidity of the development of the world, that’s why we propose an offer or exportation in partnership with different companies and more especially in China.",
    "Purchase",
    "Our purchases are made in direct, this giving us a better competitiveness over a large selection of products on the fishery of the Boulonnais, but also various sources by our logistics networks ( oyster from Normandie, King crabs from Norway,Lobster, salmo eggs, processed products …)",
    "Packaging",
    "With a number of health accreditation, we have the possibility of repackaging our products, allowing us to check the freshness but also to guarantee a good redistribution of all the products, in order to respond to every request.",
    "Delivery",
    "We offer a service of delivery provided by our team and with our services providers of delivery. Responding to your needs wherever you are located.",
    "Our main products",
    "See more",
    "Contact info",
    "<h3>Adress:</h3><p>18 rue d'Alsace,<br>62200 Boulogne-sur-mer</p><h3>Phone:</h3><p>+33 (0) 9 80 44 94 89</p><h3>Email:</h3><p>sashys2018@gmail.com</p>",
    "Get in touch",
    "Name",
    "Email",
    "Object",
    "Phone",
    "Message",
    "Send",
    "HYS offer a service that guarantee a constant quality at competitive price.",
    "18 rue d'Alsace,<br>62200 Boulogne-sur-mer<br>+33 (0) 9 80 44 94 89<br>sashys2018@gmail.com",
    "Copyright© 2021.All right Reserved",
    "Privacy Policy",
  ],
};

function replaceText(arr) {
  var list = document.getElementsByClassName("trad");
  for (var i = 0; i != 33; i++) list[i].innerHTML = arr[i];
}

var LANG = "en";

function setLang(n) {
  let lang = document.getElementsByClassName("language")[n].value;
  if (lang === "FR") {
    replaceText(trad.fr);
    replaceLogo(LANG, "en");
    LANG = "en";
  } else if (lang === "CH") {
    replaceText(trad.ch);
    replaceLogo(LANG, "ch");
    LANG = "ch";
  } else if (lang === "EN") {
    replaceText(trad.en);
    replaceLogo(LANG, "en");
    LANG = "en";
  }
}

function replaceLogo(currentLang, lang) {
  var logo = document.getElementsByClassName("logo");

  for (var i = 0; i != logo.length; i++) {
    logo[i].src = logo[i].src.replace(currentLang, lang);
  }
}
